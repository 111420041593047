import { QueryClient, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'

import { peachApi } from 'core/api'
import * as R from 'core/helpers/remeda'
import getDefaultOptions from 'core/types/hooks/defaultOptions'
import { useErrorHandler, validate } from 'core/types/utils'

export type OperationData<Op extends Operation> = {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  name: string
  summary: string
  path: string
  queryKey: (params: { [index: string]: any }) => Op['key']
}

export type Operation = {
  key: Array<string | undefined>
  requestBody: unknown
  response: unknown
  parameters: {
    [index: string]: string | undefined
  }
  query?: {
    [index: string]: unknown
  }
}

type MutationContext = { queryClient: QueryClient }

type MutationVariables<Op extends Operation> = Op['parameters'] & {
  body?: Op['requestBody']
  query?: Op['query']
}

export type MutationOptions<Op extends Operation> = Op['parameters'] & {
  options?: Omit<
    UseMutationOptions<Op['response'], unknown, MutationVariables<Op>, MutationContext>,
    'onSuccess' | 'onError'
  > & {
    onSuccess?: (
      data: Op['response'],
      variables: MutationVariables<Op>,
      context: MutationContext,
    ) => Promise<boolean | undefined | void> | boolean | undefined | void
    onError?: (
      error: unknown,
      variables: MutationVariables<Op>,
      context: MutationContext,
    ) => Promise<boolean | undefined | void> | boolean | undefined | void
  }
} & (Op extends { query: unknown } ? { query: Op['query'] | undefined }
  : Op extends { query?: unknown } ? { query?: Op['query'] }
  : { query?: unknown })

export const callAPI = async <Op extends Operation>(
  { method, name, path }: OperationData<Op>,
  { body, query, ...args }: MutationVariables<Op>,
) => {
  const res = (await peachApi.fetch({
    body,
    query,
    method,
    url: path.replace(/\{(\w+)\}/g, (_, p1) => {
      const variable = args[p1]

      if (!variable) {
        throw new Error(`Path variable "${p1}" is not defined.`)
      }

      return variable
    }),
  })) as Op['response']

  if (import.meta.env.VITE_ENABLE_RESPONSE_VALIDATION) {
    if (res) {
      validate(`http://peachfinance.com/schemas/${name}`, res)
    }
  }

  return res
}

const makeMutationHook =
  <Op extends Operation>(data: OperationData<Op>) =>
  (args: MutationOptions<Op>) => {
    const queryClient = useQueryClient()
    const onError = useErrorHandler(data.summary)

    const defaultOptions = getDefaultOptions(data)
    const { options, ...parameters } =
      defaultOptions ? (R.mergeDeep(defaultOptions, args) as unknown as typeof args) : args

    const res = useMutation<Op['response'], unknown, MutationVariables<Op>, MutationContext>({
      mutationFn: (variables) => callAPI(data, variables),
      ...options,
      onSuccess: (reponse, variables, context) => {
        const ctx = context ?? { queryClient }
        if (args.options?.onSuccess?.(reponse, variables, ctx) === true) return
        if (defaultOptions?.options?.onSuccess?.(reponse, variables, ctx) === true) return

        void queryClient.invalidateQueries({
          queryKey:
            data.method === 'post' || !data.path.endsWith('}') ?
              data.queryKey(variables)
            : R.dropLast(data.queryKey(variables), 1),
        })
      },
      onError: (e, variables, context) => {
        const ctx = context ?? { queryClient }
        if (args.options?.onError?.(e, variables, ctx) === true) return
        if (defaultOptions?.options?.onError?.(e, variables, ctx) === true) return

        onError(e)
        console.error(e)
      },
    })

    return {
      ...res,
      mutate: (body: Op['requestBody'], override?: Partial<MutationVariables<Op>>) =>
        res.mutate({ body, ...parameters, ...override }),
      mutateAsync: (body: Op['requestBody'], override?: Partial<MutationVariables<Op>>) =>
        res.mutateAsync({ body, ...parameters, ...override }),
    }
  }

export default makeMutationHook
